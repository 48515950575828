import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "type-annotations"
    }}>{`Type Annotations`}</h1>
    <hr></hr>
    <p>{`Type Annotations it's the most valuable part of Hegel.`}</p>
    <p>{`Type Annotation is an ability to declare values that are valid for the variable. Lets explore this ability.`}</p>
    <p>{`First of all, lets write a simple function in pure JavaScript.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function mul(a, b) {
  return a * b;
}
`}</code></pre>
    <p>{`So, you can apply this function with different values:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`mul(42, 42); // 1764
mul(42, "42"); // 1764
mul(true, "42"); // 42
mul(class User {}, "42"); // NaN
`}</code></pre>
    <p>{`As you can see, applying this function with different data types sometimes will return a valid result.
But, you always want to get a valid result. So, you can add types.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function mul(a: number, b: number) {
  return a * b;
}
`}</code></pre>
    <p>{`And if you try to apply function by the same way in `}<a parentName="p" {...{
        "href": "/try#GYVwdgxgLglg9mABAWxAGwBQEMBciwjIBGApgE4A0iReBx5AlIgN4BQiiZJUIZSWiAFTUA3KwC+rVqkwAWAExUFDERzXqNiAPRbEgXg3AMjsBCKTsQBRMmThk8AFQCeABxKIARAHIFHt4hgBnP0g4ZCcsWCI0VwB3GCgAC0QoZ1c3OlIyN2l0DAUqNwU3FU1NU11La1tERxd3KDIQEl8AoIgQsIioxFiEpJT3dPIsmQx6xvzC4o0yiysbe363CDQsf0CAVX8hv0CYYNDwmEiYuMTk2rTCDOGc5dWNrbIWcQn5IrEgA"
      }}>{`Playground`}</a>{` then you will see that Hegel will reject invalid data types application and will notify you about the problem.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function mul(a: number, b: number) {
  return a * b;
}

let result = mul(42, 42); // 👌!

// Error: Type "'42'" is incompatible with type "number"
result = mul(42, "42");

// Error: Type "true" is incompatible with type "number"
result = mul(true, "42");

// Error: Type "class User" is incompatible with type "number"
result = mul(class User {}, "42");
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      